<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Domeinnamen</h1>
        <p><router-link to="/">Registreer online uw domeinnaam</router-link> en kies een pakket voor webhosting dat past bij uw behoeften. Wij zorgen er met onze diensten voor dat u online gemak ervaart. De domeinnaam check kost maximaal een minuut van uw tijd, zodat u meteen na kunt gaan of het domein nog beschikbaar is, in combinatie met de extensie die u voor ogen heeft.</p>
        <p><i>Tip: domeinnamen hebben een persoonlijk gedeelte en een extensie. De .nl-extensie is in Nederland het meest populair. Daarnaast kunt u ook .eu-domeinnamen, .com-domeinnamen en tal van andere extensies registreren.</i></p>
        <p>Check of uw domeinnaam nog beschikbaar is en registreer die desgewenst meteen. Wij maken het eenvoudig met de domeinnaam checker, die direct aangeeft of een bepaald domein nog beschikbaar is. Blijkt dit niet het geval? Mogelijk is er een andere domeinnaam beschikbaar of kunt u een andere extensie kiezen om het domein toch vast te kunnen leggen.</p>
        <h3>Check uw domeinnaam</h3>
        <p>De domeinnamen check werkt heel eenvoudig, het is voldoende om de gewenste URL in te voeren. Gebruik de domein check om de gewenste domeinnamen te registreren of om na te gaan wat er op dit moment beschikbaar is. We zullen de domeinnaam die u wilt registreren aanmelden bij SIDN. We zijn registrar voor deze Nederlandse organisatie, zodat we uw domein direct kunnen vastleggen en u daar vervolgens gebruik van kunt maken.</p>
        <img
          class="framed-image"
          src="../../assets/images/domeinnamen.png"
          height="376"
          width="449"
          title="voorbeelden van domeinnamen, domeincheck, domeinnaam checker, en domein registreren"
          alt="voorbeelden van domeinnamen, domeincheck, domeinnaam checker, en domein registreren"
        >
        <p>Verschillende domeinextensies</p>
        <h3>Domeinnaam checker gebruiken</h3>
        <p>De domeinnaam checker is eenvoudig in het gebruik. Vol de domeinnaam van uw keuze in, om na te gaan of die tot de beschikbare domeinnamen behoort. Is dat het geval, in combinatie met de juiste extensie? U kunt de domeinnaam dan meteen registreren. Wij leggen die voor u vast.</p>
        <p>Uiteraard kunt u naast de domeinnaam ook meteen <router-link to="/webhosting/">webhosting bestellen</router-link>. U gebruikt dan de domeinnamen checker om na te gaan of uw domein nog beschikbaar is. Het hostingpakket kunt u gebruiken om daadwerkelijk een website met die URL te bouwen.</p>
        <RandomQuote/>
      </div>
      <DomainNameRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DomainNameRightSideBlocks from '@/components/DomainNameRightSideBlocks.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'DomainName',
  components: {
    Breadcrumbs,
    DomainNameRightSideBlocks,
    RandomQuote,
  },
}
</script>